import React, { useContext, useState } from "react";
import ToggleButton from "./ToggleButton";
import { TbCheck } from "react-icons/tb";
import { ColorContext } from "App";

const DeviceSetting = ({
  settingName,
  settingDataName,
  setting,
  metric,
  min,
  max,
  turnOffAt,
  step = 1,
  deviceSettings,
  handleInputChange,
  device,
  type,
  enabled = true,
}) => {
  const [inputValue, setInputValue] = useState(setting);

  const theme = useContext(ColorContext);

  const handleBlur = (e) => {
    let value = e.target.value;

    switch (type) {
      case "int":
        value = parseInt(value);
        break;
      case "float":
        value = parseFloat(value);
        break;
      case "string":
        value = value.toString();
        break;
      default:
        break;
    }

    handleInputChange(settingDataName, value);
  };

  const isOff = turnOffAt === null ? setting === null : setting === turnOffAt;

  const isChanged = () => {
    if (settingDataName === "ffTheshold") {
      return (
        deviceSettings[settingDataName] !==
        (device.device_settings[settingDataName] === null
          ? 0
          : device.device_settings[settingDataName])
      );
    }
    return (
      deviceSettings[settingDataName] !==
      device.device_settings[settingDataName]
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
          {isChanged() && <TbCheck color="green" size={20} />}
        </div>
        <label>{settingName}:</label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: 220,
          marginRight: 25,
        }}
      >
        <ToggleButton
          isOff={isOff}
          onClick={
            enabled
              ? () => handleInputChange(settingDataName, isOff ? step : turnOffAt)
              : () => {}
          }
        />
        {!isOff && (
          <div>
            <input
              disabled={!enabled}
              type="number"
              value={inputValue}
              min={min}
              max={max}
              step={step}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleBlur}
              style={{
                marginLeft: 5,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 5,
                width: 50,
                height: 25,
                textAlign: "center",
              }}
            />
            <span style={{ marginLeft: 5 }}>{metric}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceSetting;
