import { DeviceContext, UserAgentContext } from "App";
import BoundsSetter from "components/AlertCreator/components/BoundsSetter";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import FullScreenMap from "components/FullScreenMap";
import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { Marker, Polygon, Popup } from "react-leaflet";
import { getDynamicDeviceIcon } from "utils/iconUtils";
import DeviceMapDataView from "pages/System/DeviceMapDataView";
import MobilePopup from "../MobilePopup";
import AwareFloodDeviceSettings from "pages/System/DeviceSettings/DeviceSettings";
import RescAlertDeviceSettings from "pages/System/DeviceSettings/RescAlertDeviceSettings";

const MobileDevicesMap = () => {
  const deviceContext = useContext(DeviceContext);
  const userAgent = useContext(UserAgentContext);

  const [mapCenter, setMapCenter] = useState([0, 0]);

  //jurisdictions:
  const [jurisdictions, setJurisdictions] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [initialGrab, setInitialGrab] = useState(false);

  const [showDeviceSettingsModal, setShowDeviceSettingsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceIndex, setDeviceIndex] = useState(null);

  const renderDeviceSettings = () => {
      const type = selectedDevice.system_type + "_" + selectedDevice.device_type;
      switch (type.toLowerCase()) {
        case "aware_flood":
          return (
            <AwareFloodDeviceSettings
              setShowDeviceSettings={setShowDeviceSettingsModal}
              selectedDevice={deviceIndex}
              nodeList={deviceContext.devices}
              mobile
            />
          );
        case "rescalert devices_manual road closure":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettingsModal}
              selectedDevice={deviceIndex}
              nodeList={deviceContext.devices}
              type="manualRoadClosure"
              mobile
            />
          );
        case "rescalert devices_technology bridge_flashing lights":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettingsModal}
              selectedDevice={deviceIndex}
              nodeList={deviceContext.devices}
              type="technologyBridge"
              mobile
            />
          );
        case "rescalert devices_technology bridge_siren/giant voice":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettingsModal}
              selectedDevice={deviceIndex}
              nodeList={deviceContext.devices}
              type="technologyBridgeSiren"
              mobile
            />
          );
        default:
          return (
            <div>
              <h1>Device Settings</h1>
              <p>Device Type: {selectedDevice.device_type}</p>
            </div>
          );
      }
    };

  useEffect(() => {
    (async () => {
      let res = await api.getJurisdictions();
      res.data.result = res.data.result.filter((f) => f !== null);
      setTimeout(() => {
        setJurisdictions(res.data.result);
        const oc = JSON.parse(res.data.result[0].original_coordinates);
        //if i am tenant a, use index 0

        //   v
        // a 0 , 1 , 2
        //       v
        // b 0 , 1 , 2
        //           v
        // c 0 , 1 , 2

        //find the left, right, up, and downmost coords
        let left = 180;
        let right = -180;
        let up = -90;
        let down = 90;
        oc[0].forEach(([longitude, latitude]) => {
          if (longitude < left) left = longitude;
          if (longitude > right) right = longitude;
          if (latitude > up) up = latitude;
          if (latitude < down) down = latitude;
        });
        //pad the bounds
        const padding = 0.07;
        left -= padding;
        right += padding;
        up -= padding;
        down += padding;
        const upperLeft = [up, left];
        const lowerRight = [down, right];
        const center = [(up + down) / 2, (left + right) / 2];
        setMapCenter(center);
        setMapBounds([upperLeft, lowerRight]);
        setInitialGrab(true);
      }, 1000);
    })();
  }, []);
  return (
    <div
      className="flex column"
      style={{
        padding: 10,
      }}
    >
      {showDeviceSettingsModal && (
        <MobilePopup
          title="Device Settings"
          content={renderDeviceSettings()}
          // options={[
          //   {
          //     display: "Cancel",
          //     action: () => {
          //       setShowDeviceSettingsModal(false);
          //     },
          //     className: "bg-primaryShadow",
          //   },
          //   {
          //     display: "Save",
          //     action: () => {
          //       console.log("Save Device");
          //     },
          //     className: "bg-primary",
          //   },
          // ]}
          close={() => setShowDeviceSettingsModal(false)}
        />
      )}
      <FullScreenMap
        mapCenter={mapCenter}
        style={{
          height: userAgent.isIPhone() ? "65vh" : "75vh",
          borderRadius: 5,
          zIndex: 10,
        }}
      >
        <LocationSetter center={mapCenter} zoom={9} />
        <BoundsSetter bounds={mapBounds} />
        {jurisdictions &&
          jurisdictions.map((jurisdiction) => (
            <Polygon
              positions={JSON.parse(jurisdiction.original_coordinates)[0].map(
                ([longitude, latitude]) => [latitude, longitude]
              )}
              color="purple"
              fillOpacity={0}
            />
          ))}
        {deviceContext.devices.map((device) => {
          let POINT;

          if (device.location)
            POINT = [device.location.lat, device.location.lng];
          else {
            console.log(
              "no location or coordinates for device",
              device.node_id
            );
          }
          return (
            <Marker
              key={device.node_id}
              position={POINT}
              icon={getDynamicDeviceIcon(device, "icon")}
            >
              <Popup>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <DeviceMapDataView device={device} /> */}
                  <div
                    className="flex column"
                    style={{
                      padding: "5px 10px",
                      borderRadius: 5,
                      textAlign: "center",
                      cursor: "pointer",
                      width: "fit-content",
                      margin: "auto",
                      gap: 5,
                    }}
                    onClick={() => {
                      setMapCenter([device.location.lat, device.location.lng]);
                    }}
                  >
                    <div className="flex column">
                      <b>Device Alias:</b>
                      {device.static_id + " " + device.alias}
                    </div>
                    <div className="flex column">
                      <b>Device Type:</b>
                      {device.device_type.split("_").join(" - ")}
                    </div>
                    <button
                      className="button bg-primary"
                      style={{
                        color: "white",
                      }}
                      onClick={() => {
                        console.log("view device clicked", device);
                        //get index
                        const index = deviceContext.devices.findIndex(
                          (d) => d.id === device.id
                        );
                        setDeviceIndex(index);
                        setSelectedDevice(device);
                        setShowDeviceSettingsModal(true);
                      }}
                    >
                      View device
                    </button>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        })}
      </FullScreenMap>
    </div>
  );
};

export default MobileDevicesMap;
