import useDebounce from "hooks/useDebounce";
import React, { useState, useEffect, useContext } from "react";
import { AlertCard } from "components/AlertCard";
import Cookies from "js-cookie";
import { FaSort } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Toggle from "./UI/Toggle";
import WidgetMap from "./WidgetMap";
import { Slider } from "antd";
import { Polygon, useMap } from "react-leaflet";
import { ColorContext, LayoutSizeContext } from "App";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import PageIndex from "./UI/PageIndex";
import { usePerms } from "hooks/usePerms";
import { GetPageFromList, ProcessAlert } from "utils/alertUtils";
import WidgetLoadingAnimation from "./Widgets/WidgetLoadingAnimation";

const alertLimit = 100;

export const AlertListPage = ({
  widget,
  dimensions,
  title,
  alertsList,
  setAlertsList,
  sidebarOpen,
  ROUTE,
  preview = false,
}) => {
  const perms = usePerms();
  const layout = useContext(LayoutSizeContext);

  const navigate = useNavigate();

  const [filterInput, setFilterInput] = useState("");
  const debouncedFilter = useDebounce(filterInput, 1000);

  const [severitySortDirection, setSeveritySortDirection] = useState(null); // true is ascending, false is descending
  const [pageIndex, setPageIndex] = useState(1);

  const severitySort = () => {
    const newValue = !severitySortDirection;
    setSeveritySortDirection(newValue);
    UpdateFilteredLists({ severitySortValue: newValue });
  };

  const [maxDist, setMaxDist] = useState(100);

  const [showLocalOnly, setShowLocalOnly] = useState(false);

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [mapPolygon, setMapPolygon] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [filteredAlertListPaged, setFilteredAlertListPaged] = useState(alertsList);
  const [filteredAlertList, setFilteredAlertList] = useState(alertsList);

  useEffect(() => {
    if(!!alertsList[0].distanceToUser) return;

    (async () => {
      //Inject needed information into the alert object
      const processedAlerts = [];
      for(let i = 0; i < alertsList.length; i++)
        processedAlerts.push(await ProcessAlert(alertsList[i]));

      setAlertsList(processedAlerts);
      setFilteredAlertList(FilterAlertList(processedAlerts));
      setFilteredAlertListPaged(GetPageFromList(processedAlerts, alertLimit, 1));
    })();
  }, [alertsList, setAlertsList]);

  useEffect(() => {
    UpdateFilteredLists();
  },[debouncedFilter]);

  function ChangeView({ center, bounds }) {
    const map = useMap();
    map.setView(center, map.getBoundsZoom(bounds));
    return null;
  }

  function FilterAlertList(list, localOnly = showLocalOnly, maxDistance = maxDist) {
    return list.filter(alert => {
      //search check
      if(debouncedFilter !== "" &&
        !JSON.stringify(alert)
          .toLowerCase()
          .includes(debouncedFilter.toLowerCase())) return false;
    
      //local only check
      if(localOnly) return alert.distanceToUser && alert.distanceToUser <= maxDistance;
    
      //default
      return true;
    });
  }

  /**
   * Updates the filtered list states.
   * @param {Object} args Expected Object: { page: PageNumber, severitySortValue: SeveritySortDirectionValue, localOnly: ShowLocalOnlyBoolean, maxDistance: MaxDistanceForLocalOnly }
   * @note You only need to input the arguments for the sake of avoiding timing conflicts with useState updates
   */
  function UpdateFilteredLists(args){
    //this sets the default arguments
    const correctedArgs = { page: pageIndex, severitySortValue: severitySortDirection, localOnly: showLocalOnly, maxDistance: maxDist, ...args };

    let filteredList = FilterAlertList(alertsList, correctedArgs.localOnly, correctedArgs.maxDistance);

    //sort by severity
    if(correctedArgs.severitySortValue !== null) filteredList = filteredList.sort((a, b) => {
        if(correctedArgs.severitySortValue) return b.severity - a.severity;
        else return a.severity - b.severity;
      })

    setFilteredAlertList(filteredList);

    let filteredListPaged = GetPageFromList(filteredList, alertLimit, correctedArgs.page);

    //sort by severity
    if(correctedArgs.severitySortValue !== null) filteredListPaged = filteredListPaged.sort((a, b) => {
      if(correctedArgs.severitySortValue) return b.severity - a.severity;
      else return a.severity - b.severity;
    })

    setFilteredAlertListPaged(filteredListPaged);
  }

  const theme = useContext(ColorContext);

  if (!widget) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 50,
              }}
            >
              <div style={{ fontSize: 20 }}>{title}</div>
              <input
                style={{
                  background: theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                }}
                value={filterInput}
                onChange={(e) => setFilterInput(e.target.value)}
                placeholder="Search"
              ></input>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Show Local Only
                <Toggle
                  value={showLocalOnly}
                  callback={(value) => {
                    setShowLocalOnly(value);
                    setPageIndex(1);
                    UpdateFilteredLists({ localOnly: value });
                  }}
                  colorDisabled={theme.primaryShadow}
                />
                {showLocalOnly && (
                  <div style={{ marginLeft: 20 }}>
                    Max Distance (mi):{" "}
                    <input
                      type="number"
                      min={0}
                      max={1000}
                      value={maxDist}
                      onChange={(e) => {
                        setMaxDist(e.target.value)
                        UpdateFilteredLists({ maxDistance: e.target.value });
                      }}
                    />
                    <Slider
                      value={maxDist}
                      onChange={(value) => {
                        setPageIndex(1);
                        setMaxDist(value);
                        UpdateFilteredLists({ maxDistance: value });
                      }}
                      min={0}
                      max={1000}
                      step={5}
                      style={{ width: 150 }}
                    />
                  </div>
                )}
              </div>
            </div>
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["Create-EditAlerts"]
            ) && (
              <div
                style={{
                  padding: "10px 60px",
                  background: theme.primaryHighlight,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/publicuser/createAlert")}
              >
                Create Alert
              </div>
            )}
          </div>
          <div
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
            onClick={() => {
              severitySort();
            }}
          >
            Severity <FaSort />
          </div>
        </div>

        <div style={{ overflow: "auto" }}>
          {filteredAlertListPaged.length === 0 && <div style={{
            width: '100%',
            textAlign: 'center',
          }}>
            No Results Found...
          </div>}
          {filteredAlertListPaged.map((alert, index) => 
            <AlertCard
              index={index}
              alert={alert}
              getAlerts={() => {
                setAlertsList(alertsList.filter((a) => a !== alert));
              }}
              canCloseAlert={
                parseInt(alert.workplaceId) ===
                parseInt(Cookies.get("workplaceId"))
              }
            />
          )}
        </div>
        <PageIndex
          pageIndex={pageIndex}
          setPageIndex={(value) => {
            setPageIndex(value)
            UpdateFilteredLists({ page: value });
          }}
          maxPages={filteredAlertList.length / alertLimit}
        />
      </div>
    );
  }

  if (widget) {
    if (dimensions[0] === 1 && dimensions[1] === 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              display: "flex",
              // borderTop: "1px solid #4A4A4A",
              borderBottom: "1px solid #4A4A4A",
            }}
          >
            <p style={{ width: 90, marginLeft: 30, textAlign: "center" }}>
              Severity
            </p>
            <p style={{ width: 150, textAlign: "center" }}>Type</p>
            <p style={{ width: 220, textAlign: "center" }}>Sender</p>
          </div>

          <div
            style={{
              height: layout.widgetHeight - 135,
              overflow: "auto",
            }}
          >
            {filteredAlertListPaged.length === 0 && <div style={{
            width: '100%',
            textAlign: 'center',
          }}>
            No Results Found...
          </div>}
            {filteredAlertListPaged.map((alert, index) => 
                  <AlertCard
                    key={index}
                    widget
                    dimensions={dimensions}
                    index={index}
                    alert={alert}
                    getAlerts={() => {
                      setAlertsList(alertsList.filter((a) => a !== alert));
                    }}
                    canCloseAlert={
                      parseInt(alert.workplaceId) ===
                      parseInt(Cookies.get("workplaceId"))
                    }
                    localOnly={showLocalOnly}
                    maxDistance={maxDist}
                  />
                  )}
          </div>

          <div
            style={{
              height: 57,
              display: "flex",
              alignContent: "center",
            }}
          >
            <button
              onClick={() => navigate("/publicuser/alerts")}
              style={{
                padding: 5,
                margin: 5,
                marginLeft: 0,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
              }}
            >
              All Alerts
            </button>
            <button
              onClick={() => navigate("/publicuser/createAlert")}
              style={{
                padding: 5,
                margin: 5,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
                marginLeft: 3,
              }}
            >
              Create Alert
            </button>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <p>Local Only</p>
              <Toggle
                value={showLocalOnly}
                callback={(value) => {
                  setShowLocalOnly(value);
                  UpdateFilteredLists({ localOnly: value });
                }}
                colorDisabled={theme.primaryShadow}
              />
            </div>
            {showLocalOnly && (
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Max Distance (mi):{" "}
                <Slider
                  value={maxDist}
                  onChange={(value) => {
                    setMaxDist(value);
                    UpdateFilteredLists({ maxDistance: value });
                  }}
                  min={0}
                  max={1000}
                  step={5}
                  style={{ width: "80%" }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    if (dimensions[0] === 1 && dimensions[1] === 2) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div>
            {mapCenter && mapPolygon && mapPolygon[0][0] !== 0 && (
              <WidgetMap
                sidebarOpen={sidebarOpen}
                center={mapCenter}
                zoom={11}
                satelliteToggleStyle={{ bottom: 5, left: 5 }}
                preview={preview}
              >
                <ChangeView center={mapCenter} bounds={mapPolygon} />
                {mapPolygon.map((poly, index) => (
                  <Polygon key={index} positions={poly} />
                ))}
              </WidgetMap>
            )}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid #4A4A4A",
                borderBottom: "1px solid #4A4A4A",
              }}
            >
              <p style={{ width: 90, marginLeft: 30, textAlign: "center" }}>
                Severity
              </p>
              <p style={{ width: 150, textAlign: "center" }}>Type</p>
              <p style={{ width: 220, textAlign: "center" }}>Sender</p>
            </div>

            <div
              style={{
                height: layout.widgetHeight - 65,
                overflow: "auto",
              }}
            >
              {filteredAlertListPaged.length === 0 && <div style={{
            width: '100%',
            textAlign: 'center',
          }}>
            No Results Found...
          </div>}
              {filteredAlertListPaged.map((alert, index) => 
                    <AlertCard
                      key={index}
                      widget
                      dimensions={dimensions}
                      index={index}
                      alert={alert}
                      getAlerts={() => {
                        setAlertsList(alertsList.filter((a) => a !== alert));
                      }}
                      canCloseAlert={
                        parseInt(alert.workplaceId) ===
                        parseInt(Cookies.get("workplaceId"))
                      }
                      localOnly={showLocalOnly}
                      maxDistance={maxDist}
                      selectedAlert={selectedAlert}
                      setSelectedAlert={setSelectedAlert}
                      setMapCenter={setMapCenter}
                      setMapPolygon={setMapPolygon}
                      sidebarOpen={sidebarOpen}
                    />)}
            </div>

            <div
              style={{
                height: 57,
                display: "flex",
                alignContent: "center",
              }}
            >
              <button
                onClick={() => navigate("/publicuser/alerts")}
                style={{
                  padding: 5,
                  margin: 5,
                  marginLeft: 0,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 10,
                }}
              >
                All Alerts
              </button>
              <button
                onClick={() => navigate("/publicuser/createAlert")}
                style={{
                  padding: 5,
                  margin: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 10,
                  marginLeft: 3,
                }}
              >
                Create Alert
              </button>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <p>Local Only</p>
                <Toggle
                  value={showLocalOnly}
                  callback={(value) => {
                    setShowLocalOnly(value);
                    UpdateFilteredLists({ localOnly: value });
                  }}
                  colorDisabled={theme.primaryShadow}
                />
              </div>
              {showLocalOnly && (
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Max Distance (mi):{" "}
                  <Slider
                    value={maxDist}
                    onChange={(value) => {
                      setMaxDist(value);
                      UpdateFilteredLists({ maxDistance: value });
                    }}
                    min={0}
                    max={1000}
                    step={5}
                    style={{ width: "80%" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (dimensions[0] === 2 && dimensions[1] === 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              display: "flex",
              borderTop: "1px solid #4A4A4A",
              borderBottom: "1px solid #4A4A4A",
              justifyContent: "space-between",
            }}
          >
            <p style={{ width: 140 }}>Severity</p>
            <p style={{ width: 200 }}>Type</p>
            <p style={{ width: 200 }}>Sender</p>
            <p style={{ width: 150, marginRight: 10 }}>Status</p>
          </div>
          <div style={{ height: layout.widgetHeight - 135, overflow: "auto" }}>
          {filteredAlertListPaged.length === 0 && <div style={{
            width: '100%',
            textAlign: 'center',
          }}>
            No Results Found...
          </div>}
            {filteredAlertListPaged.map((alert, index) => 
                  <AlertCard
                    key={index}
                    widget
                    dimensions={dimensions}
                    index={index}
                    alert={alert}
                    getAlerts={() => {
                      setAlertsList(alertsList.filter((a) => a !== alert));
                    }}
                    canCloseAlert={
                      parseInt(alert.workplaceId) ===
                      parseInt(Cookies.get("workplaceId"))
                    }
                    localOnly={showLocalOnly}
                    maxDistance={maxDist}
                  />
                  )}
          </div>
          <div
            style={{
              height: 57,
              display: "flex",
              alignContent: "center",
            }}
          >
            <button
              onClick={() => navigate("/publicuser/alerts")}
              style={{
                padding: 5,
                margin: 5,
                marginLeft: 0,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
              }}
            >
              All Alerts
            </button>
            <button
              onClick={() => navigate("/publicuser/createAlert")}
              style={{
                padding: 5,
                margin: 5,
                backgroundColor: theme.primaryHighlight,
                borderRadius: 10,
                marginLeft: 3,
              }}
            >
              Create Alert
            </button>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ marginRight: 5 }}>Local Only</p>
              <Toggle
                value={showLocalOnly}
                callback={(value) => {
                  setShowLocalOnly(value);
                  UpdateFilteredLists({ localOnly: value });
                }}
                colorDisabled={theme.primaryShadow}
              />
              {showLocalOnly && (
                <div style={{ marginLeft: 10 }}>
                  Max Distance (mi):{" "}
                  <Slider
                    value={maxDist}
                    onChange={(value) => {
                      setMaxDist(value);
                      UpdateFilteredLists({ maxDistance: value });
                    }}
                    min={0}
                    max={1000}
                    step={5}
                    style={{ width: 150 }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
};
