import React, { useContext, useEffect, useState } from "react";
import { FaEllipsis } from "react-icons/fa6";
import { getDynamicDeviceIcon } from "utils/iconUtils";
import MobilePopup from "../MobilePopup";
import NodeStatus from "components/NodeStatus";
import RescAlertDeviceSettings from "pages/System/DeviceSettings/RescAlertDeviceSettings";
import { DeviceContext } from "App";
import AwareFloodDeviceSettings from "pages/System/DeviceSettings/DeviceSettings";

const MobileDevicesList = ({ groupedDevices }) => {
  const [showDeviceSettingsModal, setShowDeviceSettingsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceIndex, setDeviceIndex] = useState(null);
  const [showTypes, setShowTypes] = useState([]);

  const deviceContext = useContext(DeviceContext);

  const deviceSettingsTypeFromDevice = (device) => {
    const type = device.system_type + "_" + device.device_type;

    if (type === "rescalert devices_manual road closure")
      return "manualRoadClosure";
    if (type === "rescalert devices_technology bridge_flashing lights")
      return "technologyBridge";
    if (type === "rescalert devices_technology bridge_siren/giant voice")
      return "technologyBridgeSiren";
    return null;
  };

  const renderDeviceSettings = () => {
    const type = selectedDevice.system_type + "_" + selectedDevice.device_type;
    switch (type.toLowerCase()) {
      case "aware_flood":
        return (
          <AwareFloodDeviceSettings
            setShowDeviceSettings={setShowDeviceSettingsModal}
            selectedDevice={deviceIndex}
            nodeList={deviceContext.devices}
            mobile
          />
        );
      case "rescalert devices_manual road closure":
        return (
          <RescAlertDeviceSettings
            setShowDeviceSettings={setShowDeviceSettingsModal}
            selectedDevice={deviceIndex}
            nodeList={deviceContext.devices}
            type="manualRoadClosure"
            mobile
          />
        );
      case "rescalert devices_technology bridge_flashing lights":
        return (
          <RescAlertDeviceSettings
            setShowDeviceSettings={setShowDeviceSettingsModal}
            selectedDevice={deviceIndex}
            nodeList={deviceContext.devices}
            type="technologyBridge"
            mobile
          />
        );
      case "rescalert devices_technology bridge_siren/giant voice":
        return (
          <RescAlertDeviceSettings
            setShowDeviceSettings={setShowDeviceSettingsModal}
            selectedDevice={deviceIndex}
            nodeList={deviceContext.devices}
            type="technologyBridgeSiren"
            mobile
          />
        );
      default:
        return (
          <div>
            <h1>Device Settings</h1>
            <p>Device Type: {selectedDevice.device_type}</p>
          </div>
        );
    }
  };

  return (
    <div>
      {showDeviceSettingsModal && (
        <MobilePopup
          title="Device Settings"
          content={renderDeviceSettings()}
          close={() => setShowDeviceSettingsModal(false)}
        />
      )}
      <div className="flex column">
        {Object.keys(groupedDevices).map((type) => (
          <div key={type}>
            <div
              className="listItem title border-bottom bg-primaryShadow flex justify-between"
              onClick={() => {
                let typeFixed = type.split(" - ").join("_").toLowerCase();
                if (showTypes.includes(typeFixed)) {
                  setShowTypes(showTypes.filter((t) => t !== typeFixed));
                } else {
                  setShowTypes([...showTypes, typeFixed]);
                }
              }}
            >
              <p>{type}</p>
              {
                showTypes.includes(type.split(" - ").join("_").toLowerCase()) ? (
                  <span>-</span>
                ) : (
                  <span>+</span>
                )
              }
            </div>
            {groupedDevices[type].map((device, index) => {
              if (showTypes.includes(device.device_type.toLowerCase()))
                return (
                  <div
                    key={index}
                    className="listItem border-bottom bg-base justify-between"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedDevice(device);
                      const index = deviceContext.devices.findIndex(
                        (d) => d.id === device.id
                      );
                      setDeviceIndex(index);
                      setShowDeviceSettingsModal(true);
                    }}
                  >
                    <p
                      style={{
                        maxWidth: "60%",
                      }}
                    >
                      {/* <img src={getDynamicDeviceIcon(device)} alt="Device Icon" /> */}
                      {device.static_id} {device.alias}
                    </p>

                    <NodeStatus device={device} />
                  </div>
                );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileDevicesList;
