import { useInternalMessage } from "antd/es/message/useMessage";
import axios from "axios";
import Cookies from "js-cookie";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { clearCookies } from "utils/cookieUtils";

const LOCALHOST = "";

let logAPIuse = false;
let extensiveLogging = false;
if (process.env.NODE_ENV === "development") {
  logAPIuse = true;
}
let apiHits = 0;

let href = window.location.origin;
href = href.replace("http://", "");
href = href.replace("https://", "");
href = href.split(".")[0];

export let FUSION_PREFIX = "/fusion/";
export let TEAM_PREFIX = "";

export let CUSTOM_URL;
let hasWarned = false;

let forceActive = "";
const doLogoutNav = () => {
  clearCookies();
  window.location.href = "/";
};

async function forceSignout(options) {
  console.log("Force signout:", options, forceActive);

  //this is designed to prevent calling refresh multiple times with the same token
  if (forceActive !== "") {
    //check value of forceActive repeatedly until finished
    return new Promise(async (resolve, reject) => {
      const interval = setInterval(async () => {
        if (forceActive === "") {
          const callVal = await CallAxios({
            ...options,
            accessToken: Cookies.get("accessToken"),
            refreshToken: Cookies.get("refreshToken"),
          });
          resolve(callVal);
          clearInterval(interval);
        } else if (forceActive === "failed") {
          reject();
          clearInterval(interval);
        }
      }, 1000);

      //if original process takes too long
      setTimeout(() => {
        reject();
        clearInterval(interval);
      }, 60000);
    });
  } else {
    forceActive = "active";

    loginfo();
    const refreshToken = Cookies.get("refreshToken");
    const rememberMe = Cookies.get("rememberMe");

    //try refresh
    if (refreshToken) {
      try {
        const res = await postRefresh(refreshToken);

        if (res.message === "User authenticated") {
          console.log("Auto-refresh success");

          Cookies.set("accessToken", res.accessToken);
          Cookies.set("refreshToken", res.refreshToken);

          forceActive = "";
          const axiosRet = await CallAxios({
            ...options,
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          });

          console.log("Axios Ret:", axiosRet);

          return axiosRet;
        } else {
          //failed to refresh
          console.error("Auto-refresh failed");

          //perform logout procedure
          forceActive = "failed";
          // alert("Failed to auto refresh with refreshToken due to server response");
          doLogoutNav();
          throw new Error("Failed to auto refresh with refreshToken");
        }
      } catch (err) {
        console.error("error trying refresh:", err);
        forceActive = "failed";
        // alert("Failed to auto refresh with refreshToken due to unexpected error");
        doLogoutNav();
        throw err;
      }
    } else {
      console.error("No refreshToken found");
      forceActive = "failed";
      doLogoutNav();
      // alert("No refreshToken");
      throw new Error("No refreshToken found");
    }
  }
}

/*
Example options:
{
  type: 'post',
  url: `${BASE_URL}${FUSION_PREFIX}getAirportConditionsbyState`,
  params: { state: Cookies.get("state") },
  accessToken: Cookies.get("accessToken"),
  refreshToken: Cookies.get("refreshToken")
}

example 2:
{
  type: 'get',
  url: `${BASE_URL}${FUSION_PREFIX}getAirportConditionsbyState`,
  params: { state: Cookies.get("state") },
  accessToken: Cookies.get("accessToken"),
  refreshToken: Cookies.get("refreshToken")
}
*/

async function CallAxios(options) {
  try {
    switch (options.type) {
      case "post":
        return await axios.post(options.url, options.params, {
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
            Authorization: `Bearer ${options.accessToken}`,
            AuthRefresh: `Bearer ${options.refreshToken}`,
          },
        });
      case "get":
        return await axios.get(options.url, {
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
            Authorization: `Bearer ${options.accessToken}`,
            AuthRefresh: `Bearer ${options.refreshToken}`,
          },
          params: {
            ...options.params,
          },
        });
      default:
        throw new Error("Invalid axios call type");
    }
  } catch (error) {
    console.trace("Error:", error);
    throw error;
  }
}

async function SafeCall(options) {
  const ret = await CallAxios(options).catch(async (e) => {
    if (e.response.status === 401) {
      if (e.response.data.message === "Invalid or expried token") {
        return await forceSignout(options);
      } else if (e.response.data.message === "API DISABLED") {
        doLogoutNav();
      } else {
        console.error("Unknown 401 error", e.response.data.message);
        return await forceSignout(options);
      }
    }

    return e;
  });

  return ret;
}

if (href.includes("localhost")) {
  //use dev route
  CUSTOM_URL = "https://devapi.overwatch.ai";
  TEAM_PREFIX = "/dev";
} else if (href.includes("192")) {
  //use dev route
  CUSTOM_URL = "https://devapi.overwatch.ai";
  TEAM_PREFIX = "/dev";
} else if (href.includes("devdemo")) {
  //use dev route
  CUSTOM_URL = "https://devapi.overwatch.ai";
  TEAM_PREFIX = "/dev";
} else if (href.includes("staging")) {
  //use staging route
  CUSTOM_URL = "https://aggapi.overwatch.ai";
  TEAM_PREFIX = "/staging";
} else if (href.includes("demo")) {
  //use prod route
  CUSTOM_URL = "https://aggapi.overwatch.ai";
  TEAM_PREFIX = "/demo";
} else {
  //use custom prod route
  CUSTOM_URL = `https://aggapi.overwatch.ai`;
  TEAM_PREFIX = "/" + href;
}

if (process.env.NODE_ENV === "development") {
  // TEAM_PREFIX = "";
  // TEAM_PREFIX = "/stable";
  // TEAM_PREFIX = "/experimental";
  // TEAM_PREFIX = "/wip";`
  //////// CUSTOM SPOOFING FOR TESTING
  // CUSTOM_URL = `https://aggapi.overwatch.ai`;
  // href = "comaltx";
  // TEAM_PREFIX = "/" + href;
}

FUSION_PREFIX = TEAM_PREFIX + FUSION_PREFIX;

export const BASE_URL = CUSTOM_URL;

/////// CUSTOM SPOOFING CHECK HERE

// console.log("BASE_URL:", BASE_URL, "FUSION_PREFIX:", FUSION_PREFIX);
// console.log("FULL URL:", BASE_URL + FUSION_PREFIX + "route");

export function _Environment() {
  // if (BASE_URL.includes("pittsburghpa")) {
  //   return "PITTSBURGHPA";
  // }
  if (!BASE_URL.includes("dev") && process.env.NODE_ENV === "development") {
    //   "Warning: You are in development mode but not using the dev API"
    // )
    if (!hasWarned) {
      hasWarned = true;
      alert("Warning: You are in development mode but not using the dev API");
    }
    return href.toUpperCase();
  }
  if (BASE_URL.includes("dev")) {
    return "DEV";
  } else if ((BASE_URL + FUSION_PREFIX).includes("staging")) {
    return "STAGING";
  } else {
    return href.toUpperCase();
    return "PROD";
  }
}

const MIN_REFRESH_TIME = 1000 * 60 * (3 / 60); //seconds

let loginfoTimes = {};
const loginfo = (key = null) => {
  if (logAPIuse) {
    // Create a new error stack
    const errorStack = new Error().stack;

    // Split the stack to find the route name
    const stackLines = errorStack.split("\n");
    const routeName = stackLines
      .filter((line) => line.includes("at"))[1]
      .trim()
      .split(".")[1]
      .split(" ")[0];

    if (loginfoTimes[routeName]) {
      if (
        Date.now() - loginfoTimes[key || routeName].timestamp <
        MIN_REFRESH_TIME
      ) {
        if (extensiveLogging)
          console.warn(
            key || routeName,
            "hit too recently!",
            loginfoTimes[key || routeName].count,
            "times total - Make sure to use tryCache for GET requests.",
            "Last hit was",
            (Date.now() - loginfoTimes[key || routeName].timestamp) / 1000,
            "second(s) ago."
          );
      } else {
        if (extensiveLogging) console.log(key || routeName, "hit okay!");
      }
    }
    loginfoTimes[key || routeName] = {
      timestamp: Date.now(),
      count: loginfoTimes[key || routeName]
        ? loginfoTimes[key || routeName].count + 1
        : 1,
    };
  }
};

let cache = {
  entries: {
    base: {
      data: null,
      timestamp: null,
      status: null,
    },
  },
  get: function (key) {
    return this.entries[key];
  },
  set: function (key, value) {
    this.entries[key] = value;
  },
};

const callAndRetry = async (apiCall) => {
  const res = await apiCall().catch(async (e) => {
    if (e.response.status === 401) {
      return await forceSignout(0, apiCall);
    }
  });
  return res;
};

const tryCache = async (key, func) => {
  if (extensiveLogging) console.log("tryCache", key, "requested.");
  let entry = cache.get(key);
  if (entry) {
    if (entry.status === "fetching") {
      if (extensiveLogging)
        console.log("tryCache", key, "is already fetching...");
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          let updatedEntry = cache.get(key);
          if (updatedEntry.status === "success") {
            clearInterval(interval);
            if (extensiveLogging)
              console.log(
                "tryCache",
                key,
                "finished waiting for fresh data from another instance."
              );
            resolve(updatedEntry.data);
          }
          if (updatedEntry.status === "failed") {
            clearInterval(interval);
            reject(updatedEntry.reason);
          }
        }, 1000);
      });
    } else if (
      entry.timestamp &&
      Date.now() - entry.timestamp < MIN_REFRESH_TIME
    ) {
      if (extensiveLogging)
        console.warn("tryCache", key, "returned cached data.");
      return entry.data;
    }
  }
  cache.set(key, {
    ...key,
    status: "fetching",
  });
  loginfo(key);
  let data;

  try {
    data = await func();
  } catch (e) {
    cache.set(key, {
      status: "failed",
      reason: e,
    });
    throw e;
  }

  cache.set(key, {
    data: data,
    timestamp: Date.now(),
    status: "success",
  });
  if (extensiveLogging)
    console.warn("tryCache", key, "finished fetching fresh data.");
  return data;
};

//Used in /src/pages/Signin.js
//unprotected
export async function login(
  email,
  password,
  gdprConsent,
  gdprTermsVersion,
  gdprPolicyVersion,
  provider = null,
  instance = null
) {
  try {
    loginfo();

    if (provider) {
      const msRes = await tryMsAuthSilent(instance, ["User.ReadBasic.All"]);
      if (msRes) {
        const res = await axios.post(
          `${BASE_URL}${FUSION_PREFIX}fusionLogin`,
          {
            gdprConsent: gdprConsent,
            gdprTermsVersion: gdprTermsVersion,
            gdprPolicyVersion: gdprPolicyVersion,
            provider: provider,
          },
          {
            headers: {
              "Content-Type": "application/json",
              MsAuth: `Bearer ${msRes.accessToken}`,
            },
          }
        );

        Cookies.set("workplaceId", res.data.workplaceId);
        return res;
      } else {
        throw new Error("Failed to authenticate with Microsoft");
      }
    } else {
      const res = await axios.post(
        `${BASE_URL}${FUSION_PREFIX}fusionLogin`,
        {
          email: email,
          password: password,
          gdprConsent: gdprConsent,
          gdprTermsVersion: gdprTermsVersion,
          gdprPolicyVersion: gdprPolicyVersion,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      Cookies.set("workplaceId", res.data.workplaceId);
      return res;
    }
  } catch (error) {
    throw error;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_checkVerification(code) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}checkvalidationcode`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_setPassword(code, password) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updatePassword`,
      {
        code,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_activateAccount(code) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}validateUser`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Signin.js
//protected
export async function postRefresh(refreshToken) {
  return await axios
    .post(
      `${BASE_URL}${FUSION_PREFIX}fusionRefreshToken`,
      {
        refreshToken: refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

//Used in /src/pages/Admin/CreateUser.js
//Used in /src/pages/Signup.js
export async function createFusionUser(
  role,
  base,
  department,
  rank,
  name,
  email,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  isAdmin,
  isMaintenance,
  maintenanceOperatorLevel,
  url = window.location.origin,
  avatar = null,
  widget_list = null,
  user_save = null,
  company_save = null
) {
  const options = {
    type: "post",
    url: `${BASE_URL}${FUSION_PREFIX}fusionCreate`,
    params: {
      role,
      base,
      department,
      rank,
      name,
      email,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      isAdmin,
      isMaintenance,
      maintenanceOperatorLevel,
      avatar,
      widget_list,
      user_save,
      company_save,
      url,
    },

    accessToken: Cookies.get("accessToken"),
    refreshToken: Cookies.get("refreshToken"),
  };

  return await SafeCall(options);
}

//Used in /src/pages/Admin/EditUser.js
export async function updateUserProfile(
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact
) {
  return await SafeCall({
    type: "post",
    url: `${BASE_URL}${FUSION_PREFIX}updateFusionUserProfile`,
    params: {
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
    },

    accessToken: Cookies.get("accessToken"),
    refreshToken: Cookies.get("refreshToken"),
  });
}

export async function updateUserProfileByAdmin(
  userId,
  role,
  base,
  department,
  rank,
  name,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  isAdmin,
  isMaintenance,
  maintenanceOperatorLevel,
) {
  return await SafeCall({
    type: "post",
    url: `${BASE_URL}${FUSION_PREFIX}updateFusionProfileByAdmin`,
    params: {
      editId: userId,
      role,
      base,
      department,
      rank,
      name,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      isAdmin,
      isMaintenance,
      maintenanceOperatorLevel,
    },

    accessToken: Cookies.get("accessToken"),
    refreshToken: Cookies.get("refreshToken"),
  });
}

export async function updateUserProfileByAdminMulti(
  userId,
  base,
  department,
  rank,
  name,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  roles
) {
  return await SafeCall({
    type: "post",
    url: `${BASE_URL}${FUSION_PREFIX}updateFusionProfileByAdminMulti`,
    params: {
      editId: userId,
      base,
      department,
      rank,
      name,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      roles,
    },

    accessToken: Cookies.get("accessToken"),
    refreshToken: Cookies.get("refreshToken"),
  });
}

//Used in /src/components/AlertCreator.js
export async function postAlert(alert) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}InsertFusionAlerts`,
      params: {
        polygon: alert.coordinates,
        city: alert.city,
        state: alert.state,
        broadcastTime: new Date().toISOString(),
        issuedTime: alert.issuedTime,
        expirationTime: alert.expirationTime,
        severity: alert.severity,
        headline: alert.headline,
        description: alert.description,
        type: alert.type,
        isInternalUse: alert.isInternalUse,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/Widgets/MyAdvisoriesWidget.js
//Used in /src/pages/PublicUser/StormCenter.js
export async function getCWActiveAlerts(lat, lng) {
  try {
    const res = await tryCache(
      "getCWActiveAlerts" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getCWActiveAlerts`,
          params: {
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/Maps/MapForecast/MapForecast.js
export async function getAirportConditionsbyState(state) {
  try {
    const res = await tryCache(
      "getAirportConditionsbyState" + "-" + Cookies.get("state"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getAirportConditionsbyState`,
          params: {
            state: Cookies.get("state"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/pages/Dashboard.js
export async function get3DayAlertHistory() {
  try {
    const res = await tryCache("get3DayAlertHistory", () =>
      SafeCall({
        type: "post",
        url: `${BASE_URL}${FUSION_PREFIX}CWget3DayAlertHistory`,
        params: {},
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getFIPSPolygon(fipsCode) {
  try {
    loginfo();

    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}getFIPSPolygon`,
      params: { fipsCode: fipsCode },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/AlertCard.js
export async function setAlertEnd(alertId, workplaceId) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}setAlertEnd`,
      params: {
        alertId,
        workplaceId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function validate() {
  try {
    loginfo();

    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}fusionValidateToken`,
      params: {},
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//Used in /src/App.js
export async function getAccessLevel() {
  try {
    const res = await tryCache("checkAuthLevel", () =>
      SafeCall({
        type: "post",
        url: `${BASE_URL}${FUSION_PREFIX}checkAuthLevel`,
        params: {},
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Admin/CreateRole.js
export async function createRole(roleName, members, permissions, layout) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}createFusionRole`,
      params: {
        roleName,
        members,
        permissions,
        layout,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Admin/EditRole.js
export async function updateRole(
  roleId,
  roleName,
  members,
  permissions,
  layout
) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateFusionRole`,
      params: {
        roleId,
        roleName,
        members,
        permissions,
        layout,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Admin/EditRole.js
export async function getRole(roleId) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}getFusionRoleById`,
      params: {
        roleId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Admin/CreateUser.js
//Used in /src/pages/Admin/EditUser.js
//Used in /src/pages/Admin/RoleManager.js

export async function getAllRoles(instance = null) {
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (msRes) {
    try {
      loginfo();
      const res = await SafeCall({
        type: "post",
        url: `${BASE_URL}${FUSION_PREFIX}getFusionRoles`,
        params: {},
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          MsAuth: `Bearer ${msRes.accessToken}`,
        },
      });
      return res;
    } catch (e) {
      throw e;
    }
  } else {
    try {
      loginfo();
      const res = await SafeCall({
        type: "post",
        url: `${BASE_URL}${FUSION_PREFIX}getFusionRoles`,
        params: {},
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
        headers: {},
      });

      return res;
    } catch (e) {
      throw e;
    }
  }
}

//Used in /src/pages/Admin/CreateRole.js
//Used in /src/pages/Admin/EditRole.js
//Used in /src/pages/Admin/RoleManager.js
export async function getWorkplaceMembers(accessLevels) {
  try {
    loginfo();
    const res = await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}getAllFusionMembers`,
      params: {
        permissionLevel: accessLevels,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });

    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Admin/EditUser.js
export async function getUserProfileInfo(userId = null) {
  try {
    const res = await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}getFusionUserProfile`,
      params: {
        editId: userId,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/WeatherForecastWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getHourlyForecast(limit) {
  try {
    let date = new Date();
    // date = new Date(date.getHours() - 7);
    if (!Cookies.get("city") || !Cookies.get("state")) return;

    const res = await tryCache(
      "getHourlyForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}customWeatherCurHourlyForecast`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/PublicUser/WeatherForecast.js
export async function currentCityConditions() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "currentCityConditions" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getCurConditionsbyCity`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAstronomyData() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getAstronomyData" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getAstronomyData`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/DetailedForecast.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getDetailedForecastData() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getDetailedForecastData" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getDetailedForecastData`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );

    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/DayForecast15Widget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function get15DayForecast() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "get15DayForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}customWeatherExt15`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );

    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/AirQualityObservationsWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAirQualityObservation() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getAirQualityObservation" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}CWW_getAirQualityObservation`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/AirQualityForecastWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAirQualityForecast() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getAirQualityForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}CWW_getAirQualityForecast`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/componentsWidgets/WaveAndWindConditionsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getDetailedMarine7() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getDetailedMarine7" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getDetailedMarine7`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/SpecialReports/FireDanger.js
export async function getExtendedFireDanger() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getExtendedFireDanger" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getExtendedFireDanger`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/SpecialReports/FireDanger.js
export async function getDetailedFireDanger() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getDetailedFireDanger" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getDetailedFireDanger`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/SpecialReports/SkiReports.js
export async function getSkiReports() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getSkiReports" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getSkiSearch`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/SpecialReports/AirportDelay.js
export async function getAirportDelays() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getAirportDelays" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getAirportDelayForecast`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/componentsWidgets/SurfAlertsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getSurfAlerts() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getSurfAlerts" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getExtendedMarine5`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/components/Widgets/BuoyConditionsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getBuoyConditions() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getBuoyConditions" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getBuoyData`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
            limit: 1000,
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

//Used in /src/componentsWidgets/TideTableWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getTideTable() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "getTideTable" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getTideData`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

export async function day7HourlyForecast() {
  try {
    if (!Cookies.get("city") || !Cookies.get("state")) return;
    const res = await tryCache(
      "day7HourlyForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}customWeather7DayHourlyForecast`,
          params: {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/Maps/Earthquake/EarthquakeMap.js
export async function getEarthQuake() {
  try {
    const res = await tryCache("getEarthQuake", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getEarthQuake`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/StormCenter/HurricanesTropicalStorms.js
export async function getHurricanesHistorical() {
  try {
    const res = await tryCache("getHurricanesHistorical", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getHurricanesHistorical`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}
//Used in /src/components/StormCenter/HurricanesTropicalStorms.js
export async function getHurricanesLive() {
  try {
    const res = await tryCache("getHurricanesLive", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getHurricanesLive`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getRadarMapTile() {
  try {
    loginfo();

    return await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getRadarMapTile/${new Date().toISOString()}/${new Date().toISOString()}/future_radar/{quadkey}`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//Used in /src/pages/Dashboard.js
//Used in /src/pages/ViewAlerts.js
export async function getWorkPlaceAlerts() {
  try {
    const res = await tryCache("getWorkPlaceAlerts", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getWorkPlaceAlerts`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/components/Maps/SevereWeatherAlertsMap/components/MapPanel.js
//Used in /src/components/Widgets/NWSAlertsWidget.js
//Used in /src/pages/PublicUser/StormCenter.js
export async function getAllActiveAlerts() {
  try {
    const res = SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getAllActiveAlerts`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    return res;
  } catch (error) {
    throw error;
  }
}

//Used in /src/App.js
export async function getUserWidgetData(userId) {
  try {
    const res = await tryCache("getUserWidgetData" + "-" + userId, () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getWidgets/${userId ? userId : ""}`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

//used in /src/components/PlacesSearch.js
export async function getCoordinatesFromCityState(city, state) {
  try {
    loginfo();

    return await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getGeoLocation/${state}/${city}`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getCityStateFromCoordinates(lat, lon) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}getCityandStateAtCoordinate/`,
      params: {
        long: lon,
        lat: lat,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function updateUserWidgetLayout(layout) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateUserWidgetLayout`,
      params: {
        widgetLayout: JSON.stringify(layout),
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getUserPerms() {
  try {
    const res = await tryCache("getUserPerms", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getfusionUserPerms`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );

    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateUserPlacesData(places) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}addFusionPlaces`,
      params: {
        places: places,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getUserPlacesData() {
  try {
    const res = await tryCache("getUserPlacesData", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getFusionPlaces/`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getNodes() {
  try {
    const res = await tryCache("getNodes", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getNodes/`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getNodeDataByTimestamps(
  startTime,
  endTime,
  ids,
  checkAgainstNodeTsInstead = false
) {
  const newStart = new Date(
    Math.round(new Date(startTime).getTime() / 60000) * 60000
  );
  const newEnd = new Date(
    Math.round(new Date(endTime).getTime() / 60000) * 60000
  );
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getNodeDataByTimestamps`,
      params: {
        startTime,
        endTime,
        ids,
        checkAgainstNodeTsInstead,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (error) {
    throw error;
  }
}
export async function getGlobalActions() {
  try {
    const res = await tryCache("getGlobalActions", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getGlobalActionsByWPId/`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function insertGlobalAction(action) {
  //return {}
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}insertGlobalActions`,
      params: {
        globalAction: action,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function updateGlobalAction(id, action) {
  //return {}
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateGlobalActions`,
      params: {
        globalAction: action,
        globalActionId: id,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function setGlobalActionEnabled(id, status) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}setGlobalActionEnabled`,
      params: {
        id,
        status,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function changeAwareDeviceSettings(deviceSettings) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}changeAwareDeviceSettings`,
      params: {
        deviceSettings: deviceSettings,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function fusionVerifyLicense(key, code, lat, lon) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}fusionActivateLicense`,
      params: {
        licenseKey: key,
        code: code,
        lat: lat,
        lon: lon,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function fusionGetLicense(code) {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}fusionFetchLicense`,
      params: {
        code,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fusionAddIntegration(system) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}addIntegration`,
      params: {
        system: system,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function fusionUpdateIntegration(system) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateIntegration`,
      params: {
        system: system,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function fusionDeleteIntegration(system) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}removeIntegration`,
      params: {
        system,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getGlobalWidgetPermList() {
  try {
    const res = await tryCache("getGlobalWidgetPermList", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getGlobalWidgetPermList`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}
export async function updateFusionPassword(oldPassword, newPassword) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateFusionPassword`,
      params: {
        newPassword: newPassword,
        oldPassword: oldPassword,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

//needs to send a localhost:3000/resetPassword?code={code}
export async function sendResetEmail(email) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}requestFusionPasswordLink`,
      params: {
        email: email,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function updatePasswordWithCode(password, code) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateForgottenPassword`,
      params: {
        password: password,
        code: code,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function submitFusionInquiry(name, email, phone, notes) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}fusionInquiries`,
      params: {
        name,
        email,
        phone,
        notes,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function setFusionUserInfo(userInfo) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}setFusionUserInfo`,
      params: {
        userInfo,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function getFusionUserInfo() {
  try {
    const res = await tryCache("getFusionUserInfo", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getFusionUserInfo`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getJurisdictions() {
  try {
    const res = await tryCache("getJurisdictions", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getJurisdictions`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getAlarmHistory() {
  try {
    const res = await tryCache("getAlarmHistory", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getAlarmHistory`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getAlarmHistoryPaginated(limit, offset) {
  try {
    loginfo();
    return await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getAlarmHistoryPaginated?limit=${limit}&offset=${offset}`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function getPendingAlarmsPaginated(limit, offset) {
  try {
    loginfo();

    return await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getPendingAlarmsPaginated?limit=${limit}&offset=${offset}`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function getPendingAlarms() {
  try {
    const res = await tryCache("getPendingAlarms", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getPendingAlarms`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (e) {
    throw e;
  }
}

export async function acknowledgeAlarm(alarmId) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}acknowledgeAlarm`,
      params: {
        alarmId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export async function silenceAlarm(alarmId) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateSilence`,
      params: {
        alarmId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (e) {
    throw e;
  }
}

export function connectToLiveAlarms() {
  try {
    loginfo();
    return new EventSource(
      `${BASE_URL}${FUSION_PREFIX}live-alarms?token=${encodeURIComponent(
        Cookies.get("accessToken")
      )}`
    );
  } catch (e) {
    throw e;
  }
}

export async function deleteGlobalAction(actionId) {
  //return {}
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}delGlobalActions`,
      params: {
        globalActionId: actionId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function autoCompleteSearch(latitude, longitude, term) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}googleAutocompleteSearch`,
      params: {
        latitude,
        longitude,
        term,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export function connectToIsDataReadyForLocation(location) {
  const country = location.place_data.address_components.find(
    (item) => item.types[0] === "country"
  ).short_name;

  const locationInfo = {
    city: location.city,
    state_long: location.state_long,
    term: location.term,
    latitude: location.latitude,
    longitude: location.longitude,
    country: country,
  };
  try {
    loginfo();
    const url = `${BASE_URL}${FUSION_PREFIX}live-location-ready?token=${encodeURIComponent(
      Cookies.get("accessToken")
    )}&location=${JSON.stringify(locationInfo)}`;

    return new EventSource(url);
  } catch (e) {
    throw e;
  }
}

export async function validateRoutePerms(perms) {
  try {
    const res = await tryCache("validateRoutePerms" + "-" + perms, () =>
      SafeCall({
        type: "post",
        url: `${BASE_URL}${FUSION_PREFIX}validateRoutePerms`,
        params: {
          perms,
        },

        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteUser(deleteId) {
  //return {}
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}deleteUser`,
      params: {
        deleteId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function deleteRole(deleteId) {
  //return {}
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}deleteRole`,
      params: {
        deleteId,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function informLocationChange(location) {
  try {
    loginfo();
    const res = await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}informLocationChange`,
      params: {
        location,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });

    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateOrgName(name) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateOrganizationName`,
      params: {
        newOrganizationName: name,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getOrgName() {
  try {
    const res = await tryCache("getOrgName", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getOrganizationName`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getAuditLogs(
  pageIndex,
  PAGE_LENGTH,
  query,
  sortKey,
  sortOrder
) {
  //return {}
  try {
    const res = await tryCache(
      "getAuditLogs" + "-" + pageIndex + "-" + PAGE_LENGTH + "-" + query ||
        "" + "-" + sortKey + "-" + sortOrder,
      () =>
        SafeCall({
          type: "post",
          url: `${BASE_URL}${FUSION_PREFIX}getAuditLogs`,
          params: {
            index: pageIndex,
            batchSize: PAGE_LENGTH,
            offset: (pageIndex - 1) * PAGE_LENGTH,
            searchTerm: query,
            orderBy: sortKey,
            orderDir: sortOrder,
          },

          accessToken: Cookies.get("accessToken"),
          refreshToken: Cookies.get("refreshToken"),
        })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function editAwareAlias(nodeId, newAlias) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}editAwareAlias`,
      params: {
        nodeId,
        newAlias,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getActiveAlarms() {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}get-active-alarms`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });

    return res;
  } catch (error) {
    throw error;
  }
}

export async function logout() {
  try {
    loginfo();

    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}logout`,
      params: {},
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function exitBrowserLogout() {
  try {
    loginfo();
    return navigator.sendBeacon(
      `${BASE_URL}${FUSION_PREFIX}logout`,
      JSON.stringify({
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
  } catch (error) {
    throw error;
  }
}

export async function updatePrivacyPolicy(value) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updatePrivacyPolicy`,
      params: {
        value,
      },

      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
  } catch (error) {
    throw error;
  }
}

export async function getTermsAndConditions() {
  try {
    const res = await tryCache("getTermsAndConditions", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getTermsAndConditions`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getPolicyAndCookies() {
  try {
    const res = await tryCache("getPolicyAndCookies", () =>
      SafeCall({
        type: "get",
        url: `${BASE_URL}${FUSION_PREFIX}getPolicyAndCookies`,
        accessToken: Cookies.get("accessToken"),
        refreshToken: Cookies.get("refreshToken"),
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function tryMsAuthSilent(instance, scopes = []) {
  try {
    if (instance && instance.getAllAccounts().length > 0) {
      const accessTokenRequest = {
        scopes: scopes,
        account: instance.getAllAccounts()[0],
      };
      // const msRes = await instance.acquireTokenSilent(accessTokenRequest);
      let msRes = null;
      if (instance.getAllAccounts()[0]) {
        try {
          msRes = await instance.acquireTokenSilent(accessTokenRequest);
        } catch (err) {
          try {
            if (err instanceof InteractionRequiredAuthError) {
              msRes = await instance.acquireTokenPopup(accessTokenRequest);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      if (!msRes) {
        console.error("Could not get MS token");
        return null;
      }
      // console.log("Token retrieved successfully:", msRes);
      return msRes;
    } else {
      console.error("No MS account found");
      return null;
    }
  } catch (e) {
    console.error("Error getting MS token", e);
    return null;
  }
}

export async function ms_linkRole(instance, msGroupId, roleId) {
  loginfo();
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (!msRes) {
    throw new Error("Could not get MS token");
  }
  try {
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}linkMSGroupToRole`,
      params: {
        msGroupId,
        roleId,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        MsAuth: `Bearer ${msRes.accessToken}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function ms_updateRoleLinks(instance, links) {
  loginfo();
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (!msRes) {
    throw new Error("Could not get MS token");
  }
  try {
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}batchUpdateMsLinkRoles`,
      params: {
        roleLink: links,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        MsAuth: `Bearer ${msRes.accessToken}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function ms_syncAllUsers(instance) {
  loginfo();
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (!msRes) {
    //throw new Error("Could not get MS token");
    return null;
  }
  try {
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}MsSyncAllUsers`,
      params: {},
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        MsAuth: `Bearer ${msRes.accessToken}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function ms_getRoleLinks(instance) {
  loginfo();
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (!msRes) {
    throw new Error("Could not get MS token");
  }
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getAllMsRoleLinks`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        MsAuth: `Bearer ${msRes.accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function ms_getSecurityGroups(instance) {
  loginfo();
  const msRes = await tryMsAuthSilent(instance, ["Group.Read.All"]);
  if (!msRes) {
    throw new Error("Could not get MS token");
  }
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getMsSecurityGroups`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        MsAuth: `Bearer ${msRes.accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateRoleHierarchy(hierarchy) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateRoleHierarchy`,
      params: {
        hierarchy,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function getConnectedDevices() {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getConnectedDevices`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getConnectedDevice(deviceIdArray) {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getConnectedDevice`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      params: {
        deviceIds: deviceIdArray,
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fusionGetIntegrations() {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getIntegrations`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateConnectedDevice(device, updateStatus = false) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateConnectedDevice`,
      params: {
        device,
        updateStatus,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function removeConnectedDevice(device) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}removeConnectedDevice`,
      params: {
        device,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function addConnectedDevice(device) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}addConnectedDevice`,
      params: {
        device: device,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function addDeviceGroup(group) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}addDeviceGroup`,
      params: {
        group,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}
export async function deleteDeviceGroup(group) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}deleteDeviceGroup`,
      params: {
        group,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}
export async function editDeviceGroup(group) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}updateDeviceGroup`,
      params: {
        group,
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function getDeviceGroups() {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getDeviceGroups`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getDeviceTypes() {
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getDeviceTypes`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getAudioFile(file) {
  return `${BASE_URL}${FUSION_PREFIX}audio-uploads/${file}`;
  try {
    const res = await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}audio-uploads/${file}`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      responseType: "blob", // Ensure response is treated as binary data
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getAIAnalytics() {
  try {
    const eventTypes = [
      "911 Telephone Outage Emergency",
      "Administrative Message",
      "Air Quality Alert",
      "Air Stagnation Advisory",
      "Arroyo And Small Stream Flood Advisory",
      "Ashfall Advisory",
      "Ashfall Warning",
      "Avalanche Advisory",
      "Avalanche Warning",
      "Avalanche Watch",
      "Beach Hazards Statement",
      "Blizzard Warning",
      "Blizzard Watch",
      "Blowing Dust Advisory",
      "Blowing Dust Warning",
      "Brisk Wind Advisory",
      "Child Abduction Emergency",
      "Civil Danger Warning",
      "Civil Emergency Message",
      "Coastal Flood Advisory",
      "Coastal Flood Statement",
      "Coastal Flood Warning",
      "Coastal Flood Watch",
      "Dense Fog Advisory",
      "Dense Smoke Advisory",
      "Dust Advisory",
      "Dust Storm Warning",
      "Earthquake Warning",
      "Evacuation - Immediate",
      "Excessive Heat Warning",
      "Excessive Heat Watch",
      "Extreme Cold Warning",
      "Extreme Cold Watch",
      "Extreme Fire Danger",
      "Extreme Wind Warning",
      "Fire Warning",
      "Fire Weather Watch",
      "Flash Flood Statement",
      "Flash Flood Warning",
      "Flash Flood Watch",
      "Flood Advisory",
      "Flood Statement",
      "Flood Warning",
      "Flood Watch",
      "Freeze Warning",
      "Freeze Watch",
      "Freezing Fog Advisory",
      "Freezing Rain Advisory",
      "Freezing Spray Advisory",
      "Frost Advisory",
      "Gale Warning",
      "Gale Watch",
      "Hard Freeze Warning",
      "Hard Freeze Watch",
      "Hazardous Materials Warning",
      "Hazardous Seas Warning",
      "Hazardous Seas Watch",
      "Hazardous Weather Outlook",
      "Heat Advisory",
      "Heavy Freezing Spray Warning",
      "Heavy Freezing Spray Watch",
      "High Surf Advisory",
      "High Surf Warning",
      "High Wind Warning",
      "High Wind Watch",
      "Hurricane Force Wind Warning",
      "Hurricane Force Wind Watch",
      "Hurricane Local Statement",
      "Hurricane Warning",
      "Hurricane Watch",
      "Hydrologic Advisory",
      "Hydrologic Outlook",
      "Ice Storm Warning",
      "Lake Effect Snow Advisory",
      "Lake Effect Snow Warning",
      "Lake Effect Snow Watch",
      "Lake Wind Advisory",
      "Lakeshore Flood Advisory",
      "Lakeshore Flood Statement",
      "Lakeshore Flood Warning",
      "Lakeshore Flood Watch",
      "Law Enforcement Warning",
      "Local Area Emergency",
      "Low Water Advisory",
      "Marine Weather Statement",
      "Nuclear Power Plant Warning",
      "Radiological Hazard Warning",
      "Red Flag Warning",
      "Rip Current Statement",
      "Severe Thunderstorm Warning",
      "Severe Thunderstorm Watch",
      "Severe Weather Statement",
      "Shelter In Place Warning",
      "Short Term Forecast",
      "Small Craft Advisory",
      "Small Craft Advisory For Hazardous Seas",
      "Small Craft Advisory For Rough Bar",
      "Small Craft Advisory For Winds",
      "Small Stream Flood Advisory",
      "Snow Squall Warning",
      "Special Marine Warning",
      "Special Weather Statement",
      "Storm Surge Warning",
      "Storm Surge Watch",
      "Storm Warning",
      "Storm Watch",
      "Test",
      "Tornado Warning",
      "Tornado Watch",
      "Tropical Depression Local Statement",
      "Tropical Storm Local Statement",
      "Tropical Storm Warning",
      "Tropical Storm Watch",
      "Tsunami Advisory",
      "Tsunami Warning",
      "Tsunami Watch",
      "Typhoon Local Statement",
      "Typhoon Warning",
      "Typhoon Watch",
      "Urban And Small Stream Flood Advisory",
      "Volcano Warning",
      "Wind Advisory",
      "Wind Chill Advisory",
      "Wind Chill Warning",
      "Wind Chill Watch",
      "Winter Storm Warning",
      "Winter Storm Watch",
      "Winter Weather Advisory",
    ];
    const LENGTH = 10;
    let result = [];

    let currentTime = new Date().getTime();
    for (let i = 0; i < LENGTH; i++) {
      const randomChar = String.fromCharCode(
        65 + Math.floor(Math.random() * 3)
      ); // Random uppercase letter
      const alertId =
        randomChar + String(Math.floor(Math.random() * 1000)).padStart(3, "0");
      //                ms    s    m     h
      currentTime -= 1000 * 60 * 60 * (Math.random() * 5);

      const randomValue = Math.random();
      let scoreDiff;

      if (randomValue > 0.5) {
        scoreDiff = 1;
      } else if (randomValue < 0.4) {
        scoreDiff = -1;
      } else {
        scoreDiff = 0;
      }
      result.push({
        alertId: alertId,
        severity: Math.floor(Math.random() * 10) * 10,
        score: Math.round(Math.random().toFixed(2) * 100),
        scoreDiff: scoreDiff,
        type: eventTypes[Math.floor(Math.random() * eventTypes.length)],
        location: Cookies.get("city"),
        timestamp: new Date(currentTime).toISOString(),
        status: Math.random() > 0.5 ? 0 : 1,
      });
    }
    return {
      data: {
        result: result,
      },
    };
    loginfo();
    return await SafeCall({
      type: "get",
      url: `${BASE_URL}${FUSION_PREFIX}getConnectedDevices`,
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function killswitchDevice(id, state) {
  try {
    loginfo();
    return await SafeCall({
      type: "post",
      url: `${BASE_URL}${FUSION_PREFIX}setKillswitchDevices`,
      params: {
        id,
        state
      },
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
  } catch (error) {
    throw error;
  }
}