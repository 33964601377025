import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CurrentDevices from "components/Connections/CurrentDevices";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import DevicesMap from "./DevicesMap";
import AwareFloodDeviceSettings from "./DeviceSettings/DeviceSettings.js";
import AwareDeviceGraphs from "./AwareDeviceGraphs";
import * as api from "apis/FusionAPI";
import { width } from "@mui/system";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import TextToggle from "components/UI/TextToggle";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { usePerms } from "hooks/usePerms";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import {
  ColorContext,
  DeviceContext,
  LayoutSizeContext,
  UniqueWidgetRefreshContext,
} from "App";
import Cookies from "js-cookie";
import { useAPIData } from "hooks/useAPIData";
import { extractDateTimeFromUrl } from "utils/dateUtils";
import RescAlertDeviceSettings from "./DeviceSettings/RescAlertDeviceSettings";
import { ConnectionsContext } from "./Connections";
import useSidebarOpen from "hooks/useSidebarOpen";

export default function Devices({
  widget = false,
  widgetType = "",
  dimensions,
  uninteractable = false,
  sidebarOpen,
  preview = false,
}) {
  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);

  const perms = usePerms();
  const navigate = useNavigate();
  const location = useLocation();
  const layout = useContext(LayoutSizeContext);

  const [params, setParams] = useSearchParams();
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [selectedDevice, setSelectedDeviceIndex] = useState(0);
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);
  const [timeMultiplier, setTimeMultiplier] = useState(
    widgetType === "map" ? 30 * 1440 : 1440
  );
  const [startTimeT, setStartTimeT] = useState(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [endTimeT, setEndTimeT] = useState(new Date().getTime());
  const [startTime, setStartTime] = useState(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [updatedSelectedDevice, setUpdatedSelectedDevice] = useState(false);
  const [timeSpan, setTimeSpan] = useState();

  const [showDeviceSettings, setShowDeviceSettings] = useState(false);
  const [showCurrentDevice, setShowCurrentDeviceState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const [viewImageInWidget, setViewImageInWidget] = useState(false);

  const [mapWidth, setMapWidth] = useState(501);
  const [mapHeight, setMapHeight] = useState(451);
  const [successMessage, setSuccessMessage] = useState("");

  const [imageNodes, setImageNodes] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [showExportModal, setShowExportModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const theme = useContext(ColorContext);

  const [init, setInit] = useState(false);
  const [initHandle, setInitHandle] = useState(false);
    const [showAdvancedDate, setShowAdvancedDate] = useState(false);
    const [usingCustomDate, setUsingCustomDate] = useState(false);

  useEffect(() => {}, [showCurrentDevice]);

  const setShowCurrentDevice = (value) => {
    setShowCurrentDeviceState(value);
  };

  const setSelectedDevice = (index) => {
    setSelectedDeviceIndex(index);
    setUpdatedSelectedDevice(!updatedSelectedDevice);
  };

  const showDeviceSettingsRef = useRef(showDeviceSettings);
  const showAdvancedDateRef = useRef(showAdvancedDate)
  const usingCustomDateRef = useRef(usingCustomDate)
  const showExportModalRef = useRef(showExportModal)

  useEffect(() => {
    showDeviceSettingsRef.current = showDeviceSettings;
  }, [showDeviceSettings]);

  useEffect(() => {
    showAdvancedDateRef.current = showAdvancedDate;
  }, [showAdvancedDate]);

  useEffect(() => {
    showExportModalRef.current = showExportModal;
  }, [showExportModal]);
  
  useEffect(() => {
    usingCustomDateRef.current = usingCustomDate;
  }, [usingCustomDate]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (!showDeviceSettingsRef.current && !showAdvancedDateRef.current && !showExportModalRef.current && !usingCustomDateRef.current) {
        // console.log("updating devices");
        deviceContext.getDevices({ ignoreFetching: true });
      } else {
        // console.log("skipping update", showDeviceSettingsRef.current, showAdvancedDateRef.current, usingCustomDateRef.current);
      }
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!deviceContext.devices) return;

    if (!deviceContext.devices[selectedDevice]?.data) {
      // setSelectedDevice(0);
      return;
    }

    const foundImg = [];

    deviceContext.devices[selectedDevice].data.forEach((data, index) => {
      if (
        !foundImg
          .map((img) => {
            return (
              deviceContext.devices[selectedDevice].data[img]?.pict ===
              data?.pict
            );
          })
          .includes(true)
      ) {
        //new entry
        foundImg.push(index);
      }
    });

    setImageNodes(foundImg);
    setSelectedImg(0);
  }, [selectedDevice, deviceContext.devices]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getNodes";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  // useEffect(() => {
  //   deviceContext.getDeviceData(startTime, endTime);
  // }, [uwf[UNIQUEKEY]]);

  const [deviceTypeForGraph, setDeviceTypeForGraph] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (deviceContext.devices.length > 0) {
        const type =
          deviceContext.devices[selectedDevice].system_type +
          "_" +
          deviceContext.devices[selectedDevice].device_type;
        setDeviceTypeForGraph(type);
      }
    }, 500);
  }, [deviceContext.devices, selectedDevice]);

  useEffect(() => {
    if (init || deviceContext.devices.length === 0) return;

    //get search params
    if (params.get("device")) {
      const nodeId = parseInt(params.get("device"));
      let nodeIndex = -1;

      deviceContext.devices.forEach((node, index) => {
        if (nodeIndex >= 0) return;
        if (node.nodeId === nodeId) nodeIndex = index;
      });

      if (nodeIndex < 0) return;

      setSelectedDevice(nodeIndex);
      setPageIndex(1);
      // setShowCurrentDevice(true);
      setInit(true);
    }
  }, [deviceContext.devices]);

  if (deviceContext.fetching) {
    return <WidgetLoadingAnimation />;
  }

  const renderDeviceSettings = () => {
    const type =
      deviceContext.devices[selectedDevice].system_type +
      "_" +
      deviceContext.devices[selectedDevice].device_type;
    if (showDeviceSettings)
      switch (type.toLowerCase()) {
        case "aware_flood":
          return (
            <AwareFloodDeviceSettings
              setShowDeviceSettings={setShowDeviceSettings}
              selectedDevice={selectedDevice}
              nodeList={deviceContext.devices}
            />
          );
        case "rescalert devices_manual road closure":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettings}
              selectedDevice={selectedDevice}
              nodeList={deviceContext.devices}
              type="manualRoadClosure"
            />
          );
        case "rescalert devices_technology bridge_flashing lights":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettings}
              selectedDevice={selectedDevice}
              nodeList={deviceContext.devices}
              type="technologyBridge"
            />
          );
        case "rescalert devices_technology bridge_siren/giant voice":
          return (
            <RescAlertDeviceSettings
              setShowDeviceSettings={setShowDeviceSettings}
              selectedDevice={selectedDevice}
              nodeList={deviceContext.devices}
              type="technologyBridgeSiren"
            />
          );
          return null;
      }
  };

  const RenderGraph = () => {
    const deviceTypeStr = deviceTypeForGraph;

    switch (deviceTypeStr.toLowerCase()) {
      case "aware_flood":
        return (
          <AwareDeviceGraphs
            nodeList={deviceContext.devices}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            setPageIndex={setPageIndex}
            setShowCurrentDevice={setShowCurrentDevice}
            updatedSelectedDevice={updatedSelectedDevice}
            startTime={startTime}
            endTime={endTime}
            timeSpan={timeSpan}
          />
        );
      case "rescalert devices_manual road closure":
        return (
          <div
            style={{
              height: window.innerHeight / 3 + 50,
              width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Select a Device
          </div>
        );
      case "rescalert devices_technology bridge_flashing lights":
        return (
          <div
            style={{
              height: window.innerHeight / 3 + 50,
              width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Select a Device
          </div>
        );
      case "rescalert devices_technology bridge_siren/giant voice":
        return (
          <div
            style={{
              height: window.innerHeight / 3 + 50,
              width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Select a Device
          </div>
        );
      default:
        return (
          <div
            style={{
              height: window.innerHeight / 3 + 50,
              width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Select a Device
          </div>
        );
    }
  };

  const renderMainView = () => (
    <div
      style={{
        height:
          location.pathname === "/system/integrations"
            ? layout.height - 117
            : layout.height - 80,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            paddingRight: 10,
          }}
        >
          {!showExportModal && (
            <DevicesMap
              sidebarOpen={sidebarOpen}
              selectedDevice={selectedDevice}
              setSelectedDevice={setSelectedDevice}
              setShowCurrentDevice={setShowCurrentDevice}
              setShowDeviceSettings={setShowDeviceSettings}
              selectorMargin={"27%"}
              nodeList={deviceContext.devices}
              setPageIndex={setPageIndex}
              preview={preview}
              // deviceTypeToFilter={"flood"}
            />
          )}

          {/* Graph area */}
          <div
            style={{
              marginTop: 15,
              backgroundColor: theme.primaryShadow,
              borderRadius: 10,
              width: "100%",
            }}
          >
            {/* Aware graph */}
            {deviceContext.devices[0]?.data?.length > 0 &&
            connectionsContext.canViewDeviceType({
              system_type: "AWARE",
            }) ? (
              RenderGraph()
            ) : (
              <div
                style={{
                  height: window.innerHeight / 3 + 50,
                  width: window.innerWidth / 2.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Select a Device
              </div>
            )}
          </div>
        </div>
        <div style={{ width: 550 }}>
          {deviceContext.devices[0]?.data?.length > 0 && (
            <CurrentDevices
              loadingMessage={loadingMessage}
              setShowDeviceSettings={setShowDeviceSettings}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setShowCurrentDevice={setShowCurrentDevice}
              showCurrentDevice={showCurrentDevice}
              setSelectedDeviceData={setSelectedDeviceData}
              nodeList={deviceContext.devices}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              getNodeData={() =>
                deviceContext.getDeviceData(startTime, endTime)
              }
              handleSubmit={() => {
                deviceContext.getDeviceData(startTimeT, endTimeT).then(() => {
                  setStartTime(startTimeT);
                  setEndTime(endTimeT);
                });
                setShowCurrentDevice(false);
                // setPageIndex(0)
              }}
              startTime={startTimeT}
              setStartTime={setStartTimeT}
              endTime={endTimeT}
              setEndTime={setEndTimeT}
              timeMultiplier={timeMultiplier}
              setTimeMultiplier={setTimeMultiplier}
              setSuccessMessage={setSuccessMessage}
              successMessage={successMessage}
              showExportModal={showExportModal}
              setShowExportModal={setShowExportModal}
              setTimeSpan={setTimeSpan}
              showAdvancedDate={showAdvancedDate}
              setShowAdvancedDate={setShowAdvancedDate}
              setUsingCustomDate={setUsingCustomDate}
            />
          )}
        </div>
      </div>
      {renderDeviceSettings()}
    </div>
  );

  if (widget && deviceContext.fetching && widgetType === "map") {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <WidgetLoadingAnimation
          widgetName="DevicesMap"
          dimensions={dimensions}
        />
      </div>
    );
  }

  if (widget && deviceContext.fetching && widgetType === "graph") {
    return (
      <WidgetLoadingAnimation
        widgetName="DevicesGraph"
        dimensions={dimensions}
      />
    );
  }

  const renderWidgetGraph = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} realtime />
      <div style={{ height: "100%", overflow: "hidden", padding: 15 }}>
        {deviceContext.devices.length > 0 && (
          <AwareDeviceGraphs
            nodeList={deviceContext.devices}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            setPageIndex={setPageIndex}
            setShowCurrentDevice={setShowCurrentDevice}
            updatedSelectedDevice={updatedSelectedDevice}
            startTime={Date.now() - 24 * 60 * 60 * 1000}
            endTime={Date.now()}
            widget
            noTitle
          />
        )}
      </div>
    </div>
  );

  const renderWidgetMap = (sidebarOpen) => {
    if (widget && dimensions[0] == 2 && dimensions[1] == 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              height: layout.widgetHeight - 60,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <DevicesMap
                  sidebarOpen={sidebarOpen}
                  setViewImageInWidget={setViewImageInWidget}
                  setPageIndex={setPageIndex}
                  selectorMargin={50}
                  widget
                  selectedDevice={selectedDevice}
                  setSelectedDevice={setSelectedDevice}
                  setShowCurrentDevice={setShowCurrentDevice}
                  nodeList={deviceContext.devices}
                  longMap={viewImageInWidget ? false : true}
                  preview={preview}
                  deviceTypeToFilter={"flood"}
                />
              </div>

              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth,
                    height: layout.widgetHeight - 90,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceContext.devices &&
                  deviceContext.devices[selectedDevice] &&
                  deviceContext.devices[selectedDevice].data &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ] &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ].img_link_url ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`${
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 110,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "350px",
                        height: "460px",
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 40 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (widget && dimensions[0] == 1 && dimensions[1] == 2) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div style={{ height: layout.widgetHeight - 60 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <DevicesMap
                  sidebarOpen={sidebarOpen}
                  setViewImageInWidget={setViewImageInWidget}
                  setPageIndex={setPageIndex}
                  selectorMargin={50}
                  height={mapHeight}
                  width={mapWidth}
                  widget
                  selectedDevice={selectedDevice}
                  setSelectedDevice={setSelectedDevice}
                  setShowCurrentDevice={setShowCurrentDevice}
                  nodeList={deviceContext.devices}
                  tallMap={viewImageInWidget ? false : true}
                  preview={preview}
                  deviceTypeToFilter={"flood"}
                />
              </div>

              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceContext.devices &&
                  deviceContext.devices[selectedDevice] &&
                  deviceContext.devices[selectedDevice].data &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ] &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ].img_link_url ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`${
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 60,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        // width: "490px",
                        height: layout.widgetHeight - 60,
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 70 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (widget && dimensions[0] == 1 && dimensions[1] == 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div style={{ height: layout.widgetHeight - 60 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!viewImageInWidget && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <DevicesMap
                    sidebarOpen={sidebarOpen}
                    setViewImageInWidget={setViewImageInWidget}
                    setPageIndex={setPageIndex}
                    selectorMargin={50}
                    height={mapHeight}
                    width={mapWidth / 2}
                    widget
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    setShowCurrentDevice={setShowCurrentDevice}
                    nodeList={deviceContext.devices}
                    preview={preview}
                    deviceTypeToFilter={"flood"}
                  />
                </div>
              )}
              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    height: layout.widgetHeight - 90,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceContext.devices &&
                  deviceContext.devices[selectedDevice] &&
                  deviceContext.devices[selectedDevice].data &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ] &&
                  deviceContext.devices[selectedDevice].data[
                    imageNodes[selectedImg]
                  ].img_link_url ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`${
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 110,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceContext.devices[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].img_link_url
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        // width: "490px",
                        height: layout.widgetHeight - 110,
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 70 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  if (widget && deviceContext.devices && deviceContext.devices.length > 0) {
    if (widgetType === "graph") return renderWidgetGraph();
    if (widgetType === "map") return renderWidgetMap(sidebarOpen);
  }

  if (!widget && deviceContext.devices && deviceContext.devices.length > 0)
    return renderMainView();

  // return <WidgetLoadingAnimation />;
}
