import { ColorContext, DeviceContext, LayoutSizeContext } from "App";
import PageIndex from "components/UI/PageIndex";
import React, { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { displayStatusOptions } from "utils/iconUtils";

const DeviceHistory = ({
  device,
  selectedDevice,
  widget = false,
  size = null,
  setShowHistory = () => {},
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const itemAmount = 10;
  const deviceContext = useContext(DeviceContext);
  const theme = useContext(ColorContext);
  const layout = useContext(LayoutSizeContext);

  return (
    <div
      style={
        widget
          ? {
              margin: 5,
              padding: 10,
              background: theme.primary,
              height:
                size === "2x1"
                  ? layout.widgetHeight - 90
                  : layout.widgetHeight * 2 - 80,
              overflow: "auto",
            }
          : {
              height: window.innerHeight / 3 + 50,
              maxHeight: "50%",
              overflowY: "auto",
              background: theme.primaryShadow,
              marginTop: 20,
              // width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              padding: 10,
              textAlign: "center",
            }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {widget ? (
              <div onClick={() => setShowHistory(false)}>
                <FaChevronLeft />
              </div>
            ) : (
              <div style={{ width: 25 }}></div>
            )}
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2>Device History</h2>
              <div>
                {(deviceContext.devices[selectedDevice].device_type !==
                "Manual Road Closure"
                  ? deviceContext.devices[selectedDevice].static_id + " "
                  : "") + deviceContext.devices[selectedDevice].alias}
                ({deviceContext.devices[selectedDevice].node_id})
              </div>
            </div>
            <div
              style={{
                width: 25,
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr 1fr 3fr",
                textAlign: "left",
              }}
            >
              <div>Date/Time</div>
              <div>Status</div>
              <div>Comment</div>
            </div>
            {deviceContext.devices[selectedDevice].data.length > 0
              ? deviceContext.devices[selectedDevice].data
                  .slice((pageIndex - 1) * itemAmount, pageIndex * itemAmount)
                  .map((data, index) => {
                    return (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1.5fr 1fr 3fr",
                          borderBottom: "1px solid white",
                          padding: 5,
                          textAlign: "left",
                          gap: 10,
                        }}
                      >
                        <div>{new Date(data.ts).toLocaleString()}</div>
                        <div>
                          {data.status
                            ? displayStatusOptions(device)[1]
                            : displayStatusOptions(device)[0]}
                        </div>
                        <div>
                          {data.comments || (
                            <div style={{ color: "grey" }}>No info</div>
                          )}
                        </div>
                      </div>
                    );
                  })
              : "No Data Available"}
          </div>
        </div>
        <div>
          <PageIndex
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            maxPages={
              Math.floor(
                deviceContext.devices[selectedDevice].data.length / itemAmount
              ) + 1
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceHistory;
